.pedido__item{
	display: flex;
	overflow: hidden;
}
.pedido__id__item,
.pedido__valor__item,
.pedido__status__item__2,
.pedido__btn__item{
	padding: 10px 15px;
}
.pedido__valor,
.pedido__status,
.segura__status__item,
.segura__valor__item{
	font-size: 20px;
	line-height: 25px;
}
.pedido__id__item{
	background-color: #f5f5f5;
	color: #2c2c2c;
	font-family: 'notosansregular';
	font-size: 22px;
	line-height: 24px;
	transition: all 0.3s linear;
	position: relative;
	padding: 20px 40px;

	& > *{
		position: relative;
		z-index: 400;
	}
	&:after{
		width: 100%;
		left: 0px;
		top: 0px;
		height: 100%;
		background: rgb(24,65,119);
		background: linear-gradient(0deg, rgba(24,65,119,1) 0%, rgba(254,163,181,1) 100%);
		z-index: 200;
		content: '';
		position: absolute;
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.pedido__item{
	display: flex;
	margin-bottom: 20px;
}

.titulo__interna{
    &.interna__pedido__detalhe{
        margin-bottom: 20px;
        color: #2c2c2c;
    }
}
.pedido__realizado{
	display: flex;
	align-items: center;
	font-family: 'notosansregular';
	font-size: 15px;
	line-height: 17px;
}
.pedido__numero{
	margin-bottom: 3px;
}
.pedido__data{
	margin-left: 5px;
}
.pedido__status__item__2,
.pedido__valor__item,
.pedido__btn__item{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	font-family: 'notosansregular';
	color: #2c2c2c;
	text-align: center;

	.pedido__valor,
	.pedido__status{
		font-family: 'notosansbold';
	}
}
.pedido__btn{
	display: inline-flex;
	min-width: 200px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: #2c2c2c;
	color: #fff;
	font-family: 'notosansbold';
	transition: all 0.3s linear;
	font-size: 16px;
	line-height: 16px;
	border: none;
	position: relative;
	border-radius: 25px;
	letter-spacing: 1px;

	&:after{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #af1a16;
		background: linear-gradient(0deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1)  100%);
		z-index: 40;
		content: '';
		border-radius: 25px;
		opacity: 0;
		transition: all 0.3s linear;
	}

	span{
		position: relative;
		z-index: 50;
	}

	&:hover{
		color: #fff;
		text-decoration: none;

		&:after{
			opacity: 1;
		}
	}
}
.pedidos__listagem{
    width: 100%;
    margin-left: auto;
    margin-right: auto;

	.carrinho__item{
		.carrinho__produto{
			border: none;
		}
		.carrinho__quantidade{
			border: none;
		}
		.carrinho__valor__uni{
			border: none;
		}
		.carrinho__valor__total{
			border: none;
		}
	}
}
.pedido__detalhe__header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 26px;
	align-items: center;
}
.pedido__detalhes__numero{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	color: #2c2c2c;
}
.pedidos__detalhes__segura__btn{
	display: flex;
	justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
	margin-bottom: 30px;

    .interna__btn{
        min-width: 255px;
    }

	.voltar__btn{
        margin: 5px 10px 5px 0px;
        min-width: 140px;
    }
}
.pedido__detalhe__dados{
	border: none;
	padding: 25px 100px;
	display: flex;
	font-family: 'notosansregular';
	font-size: 16px;
	line-height: 18px;
	justify-content: space-between;
	color: #2c2c2c;
	margin-bottom: 25px;
	background-color: #f8f7f7;

	span{
		display: inline-block;
		margin-bottom: 5px;
	}

	@include media-breakpoint-down (md) {
		padding: 25px 20px;
	}
}
.titulo__interna__detalhes{
	padding: 30px 0px 20px 0px;
	font-family: 'notosansbold';
	color: #2c2c2c;
	font-size: 25px;
	line-height: 29px;
	border-top: 7px solid #f5f5f5;
}
.pedido__detalhe__status{
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	align-items: center;
}
.titulo__pedido__status{
	font-family: 'notosansbold';
	font-size: 20px;
	line-height: 24px;
	color: #2c2c2c;
}
.pedido__detalhes__titulo{
    font-family: 'notosansbold';
    font-size: 30px;
    line-height: 34px;
    color: #2c2c2c;
    margin-bottom: 40px;
}
.pedido__status__estagio{
	display: flex;
	justify-content: flex-end;
}
.pedido__status__item{
	display: flex;
	padding: 7px 5px 8px 12px;
	background-color: #EEEEEE;
	color: #2c2c2c;
	font-family: 'notosansregular';
	font-size: 14px;
	line-height: 14px;
	position: relative;
	text-align: center;
	justify-content: center;
	align-items: center;

	.caminhao__icone{
		background-color: #2c2c2c;
		transition: all 0.3s linear;
	}

	&.pedido__status__item--active{
		background: #af1a16;
		background: linear-gradient(0deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1)  100%);
		color: #fff;
		font-family: 'notosansregular';

		.caminhao__icone{
			background-color: #fff;
		}

		&:after{
			background: #af1a16;
			background: linear-gradient(0deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1)  100%);
		}
	}

	&:first-child{
		&:before{
			display: none;
		}
	}
	&:last-child{

		span{
			margin-right: 10px;
		}
		&:after{
			display: none;
		}
	}
	&:before{
		content: '';
		position: absolute;
		left: -1px;
		top: 0px;
		width: 12px;
		height: 100%;
		background-color: #fff;
		clip-path: polygon(100% 50%, 0 0, 0 100%);
	}
	&:after{
		content: '';
		position: absolute;
		width: 12px;
		height: 100%;
		top: 0px;
		right: -11px;
		background-color: #eeeeee;
		clip-path: polygon(100% 50%, 0 0, 0 100%);
	}
}
.pedido__detalhe__listagem{
	.carrinho__valor__total{
		.segura__unidade__valor{
			span{
				font-family: 'notosansbold';
			}
		}
	}
	.carrinho__valor__uni{
		.segura__unidade__valor{
			.carrinho__valor__unidade{
				font-family: 'notosansbold';

				.numero__valor__unidade{
					font-family: 'notosansbold';
				}
			}
		}
	}
}
.pedido__pagamento__titulo{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	color: #2c2c2c;
	margin-bottom: 30px;
}
.pedido__pagamento__conteudo{
	display: flex;
	font-family: 'notosansregular';
	font-size: 18px;
	line-height: 20px;
	color: #2c2c2c;
}
.pedido__detalhe__pagamento{
	padding-top: 30px;
}
.pedido__segura__pagamento__forma{
	padding: 26px 20px;
	background-color: #f5f5f5;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	font-family: 'notosansregular';
	color: #2c2c2c;
	font-size: 16px;
	line-height: 18px;
}
.row__pesquisa{
	.produto__card{
		margin-bottom: 20px;
	}
}
.pedido__segura__pagamento{
	display: flex;
	justify-content: center;
	position: relative;
	background-color: #eeeeee;
	font-size: 16px;
	line-height: 18px;
}
.pedido__segura__pagamento__valor,
.pedido__segura__frete__valor{
	padding: 26px 50px;
	font-family: 'notosansregular';
	margin-right: 20px;
	background-color: #f5f5f5;
}
.pedido__segura__total__valor{
	background-color: #2c2c2c;
	padding: 26px 30px;
	color: #fff;
	font-family: 'notosansbold';
}
.pedido__total__valor,
.pedido__frete__valor,
.pedido__pagamento__valor,
.pedido__pagamento__forma{
	font-size: 18px;
	line-height: 20px;
	margin: 2px;
}
.row__pesquisa{
	margin-bottom: 30px;
}
.pedido__pagamento__btn{
	padding-top: 25px;
	display: flex;
	justify-content: flex-end;
}
.row__categ__produtos{
	.col-xl-4{
		margin-bottom: 20px;
	}
}
.caminhao__icone{
    width: 23px;
    height: 17px;
    mask-image: url('../images/caminhao__icone.png');
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
	background-color: currentColor;
    min-width: 23px;
    margin-right: 5px;
}
@include media-breakpoint-up (lg) {
	.pedido__id__item{
		width: 100%;
		max-width: 30%;
	}
	.pedido__valor__item{
		width: 100%;
		max-width: 20%;
	}
	.pedido__status__item__2{
		width: 100%;
		max-width: 30%;
	}
	.pedito__btn__item{
		width: 100%;
		max-width: 20%;
	}
	.pedido__detalhes__numero{
		width: 100%;
		max-width: 60%;
	}
	.pedido__detalhes__segura__btn{
		width: 100%;
		max-width: 40%;
	}
	.pedido__detalhe__data__item{
		width: 100%;
		max-width: 25%;
	}
	.pedido__detalhe__endereco__item{
		width: 100%;
		max-width: 75%;
	}
	.pedido__status__item{
		width: 180px;
		margin: 0px 6px;

		&:last-child{
			width: 200px;
			margin: 0px 0px 0px 6px;
		}
	}
}
.carrinho__header{
	&.pedido__interna{
		background: #2c2c2c !important;
	}
}
@include media-breakpoint-down (md) {
	.pedido__item{
		flex-direction: column;
		text-align: center;
	}
	.pedido__realizado{
		justify-content: center;
	}
	.segura__valor__item,
	.segura__status__item{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.pedido__detalhe__header{
		flex-direction: column;
		align-items: center;

		.pedido__detalhes__numero{
			margin-bottom: 10px;
		}
	}
	.pedido__detalhe__status{
		flex-direction: column;
	}
	.titulo__pedido__status{
		margin-bottom: 10px;
	}
	.pedido__status__estagio{
		flex-direction: column;
	}
	.pedido__status__item{
		margin: 10px 0px;
		padding: 46px 15px 20px 15px;

		&:after{
			width: 100%;
			height: 20px;
			background-color: #eeeeee;
			clip-path: polygon(50% 100%, 100% 0, 0 0);
			left: 0px;
			top: calc(100% - 1px);
		}
		&:before{
			top: 0px;
			left: 0px;
			width: 100%;
			height: 20px;
			background-color: #fff;
			clip-path: polygon(50% 100%, 100% 0, 0 0);
		}
	}
	.pedido__pagamento__conteudo{
		flex-direction: column;

		.pedido__segura__pagamento__forma{
			margin-right: 0px;
			margin-bottom: 20px;

		}
	}
	.pedido__pagamento__btn{
		justify-content: center;
	}
	.pedido__segura__pagamento{
		justify-content: center;
		width: 100%;
	}
	.pedido__segura__pagamento__forma{
		width: 100%;
		align-items: center;
	}
	.pedido__detalhe__dados{
		flex-direction: column;
		text-align: center;
		align-items: center;
	}
	.pedido__detalhe__data__item{
		margin-bottom: 10px;
	}
}
@include media-breakpoint-down (xs) {
	.pedido__segura__pagamento{
		flex-direction: column;
		padding: 0px;
	}
	.pedido__segura__pagamento__valor,
	.pedido__segura__frete__valor,
	.pedido__segura__total__valor{
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
	.pedidos__detalhes__segura__btn{
		flex-direction: column;
		align-items: center;

		.pedido__detalhes__btn{
			margin: 10px 0px;
		}
	}
}
