.alertas__custom{
	position:fixed;
	top:10px;
	right:10px;
	z-index:900;

	.alerta__custom{
		border-radius:0;
		color:#FFF;
		box-shadow: 0 0 4px rgba(#000,0.8);
		animation:rubberBand 1s linear;
		max-width:380px;

		@include media-breakpoint-down(sm){
			width: 220px;
		}

		h2{
			font-size:18px;
		}
	}
}

.alerta-danger{
	background: darken(red,10%);
	color: #FFF;
}

.alerta-success{
	background: #2DB200;
	color: #FFF;
}

.alerta-warning{
	background: darken(orange,3%);
	color: #FFF;
}

.alerta-info{
	background: lighten(blue,10%);
	color: #FFF;
}

.alert-default{
	background: #EEE;
}
