.endereco__item{
	margin-bottom: 30px;
	font-family: 'notosansregular';
	font-size: 14px;
	line-height: 24px;
	color: #2c2c2c;
}
.endereco__titulo{
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 18px;
	font-family: 'notosansbold';
	color: #2c2c2c;
}
.endereco__interna{
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.endereco__btns{
	display: flex;
	flex-wrap: wrap;
	padding-top: 10px;

	.interna__btn{
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
.v-select{
    &.show{
        z-index: 600;
    }
}
.endereco__listagem{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
}
.segura__endereco__item{
	input{
		position: fixed;
		left: -100vw;

		&:checked{
			~ label{

				// &:before{
				// 	border-color: #2c2c2c;
				// 	background-color: #fff;
				// }
				&:after{
					opacity: 1;
				}
			}
		}
	}
	label{
		position: relative;
		margin-bottom: 0px;
		padding-left: 25px;
		display: block;
		margin-left: 10px;
		cursor: pointer;
		color: #2c2c2c;
		font-family: 'notosansregular';
		font-size: 17px;
		line-height: 20px;

		&:before{
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 2px solid #2c2c2c;
			transition: all 0.3s linear;
			background-color: transparent;
			z-index: 10;
		}

		&:after{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #2c2c2c;
			top: 5px;
			left: 5px;
			transition: all 0.3s linear;
			opacity: 0;
			z-index: 5;
		}
	}
}
.endereco__form,
.custom__form{
	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		padding: 15px 20px;
		background-color: #f5f5f5;
		font-family: 'notosansregular';
		font-size: 15px;
		line-height: 15px;
		color: #2c2c2c;
		border: none;
		border-radius: 27px;
		height: 54px;

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 4);
		border-radius: 0px;
        z-index: 500;
		box-shadow: 0px 0px 5px rgba(#2c2c2c, 0.3);
		background-color: #ededed;

		li{
			color: #2c2c2c;
			font-family: 'notosansregular';

			&.selected{
				background-color: #2c2c2c42A;
				color: #fff;
			}
		}
	}
}
.endereco__listagem{
	padding-bottom: 30px;
}
.endereco__listagem__detalhe{
	padding-bottom: 20px;
	border-bottom: 11px solid #EEEEEE;
	margin-bottom: 20px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.form__item{
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 18px;
}
.endereco__form{
	width: 100%;
	margin-right: auto;
	margin-left: auto;
    padding-top: 30px;

    .form-group{
        margin-bottom: 8px;
    }
}
.endereco__detalhe__btn{
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	.interna__btn{
		margin: 5px;
	}
}
.endereco__adicionar{
	display: flex;
	flex-direction: column;
	align-items: center;
	.interna__btn{
		margin-bottom: 20px;
	}
}
