.internas{
	padding: 124px 0px 100px;
}
.titulo__interna{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 32px;
	position: relative;
	margin-bottom: 30px;
	color: #2c2c2c;
	padding-bottom: 8px;
	border-bottom: 3px solid #eb3237;
}
.interna__input{
	width: 100%;
	display: block;
	padding: 15px 20px;
	background-color: #f8f7f7;
	font-family: 'notosansregular';
	font-size: 15px;
	line-height: 15px;
	color: #2c2c2c;
	border: none;
	border-radius: 10px;
	font-weight: 900;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #2c2c2c;
	}
}
.segura__interna__alterar{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.interna__btn{
	display: inline-flex;
	min-width: 255px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: #2c2c2c;
	color: #fff;
	font-family: 'notosansbold';
	transition: all 0.3s linear;
	font-size: 15px;
	line-height: 15px;
	border: none;
	position: relative;
	border-radius: 10px;
	text-transform: uppercase;
	letter-spacing: 1.5px;

	&:after{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #af1a16;
		background: linear-gradient(0deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1)  100%);
		z-index: 40;
		content: '';
		border-radius: 10px;
		opacity: 0;
		transition: all 0.3s linear;
	}

	span{
		position: relative;
		z-index: 50;
	}

	&:hover{
		color: #fff;
		text-decoration: none;

		&:after{
			opacity: 1;
		}
	}

	&.voltar__btn{
		min-width: 160px;
	}
}
.blog__interna{
	.blog__card__titulo{
		color: #2c2c2c;
	}
}
.categoria__desc{
	margin-bottom: 30px;
}
.btn__end__carrinho{
	display: flex;
	justify-content: flex-end;
	margin-bottom: 30px;
}
.btn__end{
	display: flex;
	justify-content: flex-end;
	padding-top: 40px;
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#343338, 0.4);
        width: 100%;
		text-align: center;
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.contato__btn__center{
	display: flex;
	justify-content: center;
	padding-top: 30px;
}
.oferta__popup{
	.modal-content{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		background-attachment: fixed;
	}

	.oferta__botao{
		font-size: 26px;
		line-height: 26px;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #2c2c2c;
		color: #fff;
		margin-bottom: 150px;
		transition: all 0.3s linear;

		@include media-breakpoint-down (md) {
			margin-bottom: 30px;
		}

		&:hover{
			text-decoration: none;
			background-color: #af1a16;
			color: #fff;
		}
	}
}
.segura__imagem__grande{
	position: relative;
}
.produto__card{
	&.produto__card__oferta{
		background-color: #fff;
		position: relative;
		display: block;
		max-width: 330px;
		width: 100%;
		margin-left: auto;
		margin-right: 0px;
		border-radius: 20px;

		img{
			border-radius: 30px;
		}

		&:after{
			width: calc(100% + 20px);
			border: 1px solid #fff;
			border-radius: 25px;
			height: calc(100% + 20px);
			position: absolute;
			left: -10px;
			top: -10px;
			content: '';
		}
		.produto__card__valor{
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 35px;
			justify-content: space-between;

			.parcela__valores{
				max-width: 90px;
				width: 100%;
			}
		}
		.produto__card__tags{
			position: absolute;
			top: 20px;
			left: 10px;
			display: inline-flex;
			align-items: flex-start;
			flex-direction: row;
			bottom: unset !important;

			.produto__card__tag__oferta{
				background: #af1a16;
				background: linear-gradient(180deg, rgba(#af1a16,1) 0%, rgba(#8c2c26,1) 100%);
				color: #fff;
				font-family: 'notosansbold';
				font-size: 24px;
				line-height: 24px;
				padding: 6px;
				margin: 2px;

				&.clock{
					background-color: #2c2c2c;
				}

				i{
					font-style: normal !important;
				}

				span {
					margin-right: 3px;
					display: inline-block;
				}
			}
		}
		.produto__card__titulo{
			&:after,
			&:before{
				display: none;
			}
		}

	}
}
.ofertas__carousel{
	.carousel-item{
		padding: 10px;
	}
	.carousel-controls__custom{
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		line-height: 26px;
		padding-top: 0px;
		color: #fff;

		a,
		button{
			padding: 5px;
			transition: all 0.3s linear;

			&:hover{
				color: #af1a16;
			}
		}
	}
}
.ofertas__botao{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 330px;
	height: 80px;
	background-color: #da372e;
	color: #fff;
	font-size: 26px;
	line-height: 30px;
	font-family: 'notosansbold';
	border-radius: 20px;
	transition: all 0.3s linear;
	max-width: 100%;
	text-align: center;
	padding: 5px;

	&:hover{
		text-decoration: none;
		background-color: #2c2c2c;
		color: #fff;
	}
}
.segura__oferta__carousel{
	max-width: 330px;
	width: 100%;
	margin-left: auto;
	margin-right: 0px;

	.ofertas__botao{
		margin-top: 10px;
	}
}
