@font-face {
    font-family: 'notosansbold';
    src: url('../fonts/notosansbold/NotoSans-Bold.woff2') format('woff2'),
        url('../fonts/notosansbold/NotoSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'notosanslight';
    src: url('../fonts/notosanslight/NotoSans-Light.woff2') format('woff2'),
        url('../fonts/notosanslight/NotoSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'notosansregular';
    src: url('../fonts/notosansregular/NotoSans-Regular.woff2') format('woff2'),
        url('../fonts/notosansregular/NotoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

