.segura__cadastro__nav{
	font-family: 'notosansbold';
	font-size: 14px;
	line-height: 14px;
	color: #2c2c2c;
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	.radio__opcao{
		margin-right: 20px;
		font-family: 'notosansbold';
	}
}
.titulo__form{
	font-family: 'notosansbold';
	font-size: 25px;
	line-height: 29px;
	color: #2c2c2c;
	margin-bottom: 16px;
}
.cadastro__header{
	display: flex;
	justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-start;
}
.cadastro__titulo{
	font-family: 'notosansbold';
	font-size: 24px;
	line-height: 28px;
	color: #2c2c2c;
}
.obrigatorio{
	font-size: 14px;
	line-height: 15px;
    color: #2c2c2c;
    font-family: 'notosansregular';
	text-align: right;
	margin-top: 2px;
}

.cadastro__form{
    .form-group{
        margin-bottom: 10px;
    }
	// .row{
	// 	max-width: 920px;
	// 	width: 100%;
	// 	margin-right: auto;
	// 	margin-left: auto;
	// }
}
.segura__cadastro__header{
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		align-items: flex-start;
	}

	.titulo__form{
		margin-right: 20px;
		margin-bottom: 0px;
		@include media-breakpoint-down (md) {
			margin-bottom: 20px;
		}
	}
	.segura__cadastro__nav{
		margin-bottom: 0px;
	}
}
.franqueado{
	.obrigatorio{
		margin-bottom: 30px;
	}
}
.segura__cadastro__conteudo{
	max-width: 960px;
	width: 100%;
	margin-right: auto;
    margin-left: auto;
}
.endereco__cadastro__titulo{
	font-family: 'notosansbold';
	font-size: 25px;
	line-height: 28px;
	color: #2c2c2c;
	padding-top: 40px;
	margin-bottom: 10px;
}
.endereco__cadastro__desc{
	font-size: 14px;
	line-height: 16px;
    color: #2c2c2c;
    font-family: 'notosansregular';
	margin-bottom: 30px;
}
.segura__cadastro__btns{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;

	.interna__btn{
        &:nth-child(1) {
            margin-right: 10px;
        }
    }
}
.segura__check{
	padding-top: 30px;
	font-size: 14px;
	line-height: 16px;
	display: flex;
    align-items: center;
    font-family: 'notosansregular';
	color: #2c2c2c;

	label{
		color: #2c2c2c;

		&:after{
			background-color: #2c2c2c;
		}
		&:before{
			border-color: #2c2c2c;
		}
	}

	&.check__contato{
		align-items: flex-start;

		.check__label__cadastro{
			&:after{
				top: 7px;
			}
			&:before{
				top: 7px;
			}
		}
	}
}
.segura__genero{
	display: flex;
	align-items: center;
	height: 48px;
    padding-left: 10px;
	font-family: 'notosansregular';
	color: #2c2c2c;
	font-size: 14px;
	line-height: 14px;

	label{
		color: #2c2c2c;

		&:after{
			background-color: #2c2c2c;
		}
		&:before{
			border-color: #2c2c2c;
		}
	}

    @include media-breakpoint-down (xs) {
        height: auto;
    }

	span{
		margin-right: 5px;
		font-family: 'notosansregular';
	}
}
.radio__input{
	position: fixed;
	left: -100vw;

	&:checked{
		~ .radio__label{

			// &:before{
			// 	border-color: #2c2c2c;
			// 	background-color: #fff;
			// }
			&:after{
				opacity: 1;
			}
		}
	}
}
.radio__label{
	position: relative;
	margin-bottom: 0px;
	padding-left: 25px;
	display: block;
	margin-left: 10px;
	cursor: pointer;
	color: #2c2c2c;
	font-family: 'notosansregular';
	font-size: 14px;
	line-height: 14px;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 1px solid #2c2c2c;
		transition: all 0.3s linear;
		background-color: transparent;
		z-index: 10;
		background-color: #fff;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #2c2c2c;
		top: 50%;
		left: 4px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
		z-index: 20;
	}
}
@include media-breakpoint-up (lg) {
	.cadastro__header{
		.segura__cadastro__header,
		.obrigatorio{
			max-width: 50%;
			width: 100%;
		}
	}
}
@include media-breakpoint-down (xs) {
	.segura__cadastro__btns{
		flex-direction: column;
		align-items: center;

		.interna__btn{
			margin: 5px 0px;
		}
	}
	.segura__cadastro__nav{
		flex-direction: column;
		align-items: center;

		span{
			margin-right: 0px;
		}

		.nav-item{
			margin: 5px 5px;
		}
	}
	.segura__genero{
		flex-direction: column;
		align-items: flex-start;

		span{
			margin-bottom: 5px;
		}

		.radio__label{
			margin-bottom: 5px;
		}
	}
}
@keyframes fadeCustom{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 100%;
	}
}
