.categorias__menu{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;

}
.topo__pesquisa{
	position: fixed;
	top: -200px;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	z-index: 1080;
	transition: all 0.5s linear;

	&.shown{
		top: 40vh;
	}
}
.segura__carrinho__topo{
	position: relative;
	display: flex;
	align-items: center;

	.icon__mask{
		transition: all 0.3s linear;
	}

	a,
	button{
		&:hover{
			.icon__mask{
				color: #af1a16;
			}
		}
	}

	.carrinho__btn__topo{
		display: flex;
		align-items: center;
		position: relative;

		.carrinho__icone__topo{
			margin-left: 0px;
		}

		.carrinho__numero{
			padding: 0px;
			font-family: 'notosansbold';
			position: absolute;
			bottom: -5px;
			left: -5px;
			background-color: #2c2c2c;
			color: #fff;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			font-size: 12px;
			line-height: 12px;
		}
	}
}
.segura__login__topo{
	font-family: 'notosansregular';
	color: #2c2c2c;

	@include media-breakpoint-down (md) {
		margin-top: 10px;
	}

	.icon__mask{
		transition: all 0.3s linear;
	}

	a,
	button{
		&:hover{
			.icon__mask{
				color: #af1a16;
			}
		}
	}
	span{
		font-family: 'notosansbold';
	}
	.carrinho__icone__topo{
		margin-right: 5px;
	}
	.login__toggler{
		display: flex;
		align-items: center;
	}
	&.logado{
		.login__toggler{
			display: none;
		}
		.logado__toggler{
			display: flex;
			align-items: center;

			& > *{
				margin-right: 5px;
			}
		}
	}
	.logado__toggler{
		display: none;
	}
}
.logado__item{
    padding: 0px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 8px 15px 8px 15px;
        font-size: 15px;
        line-height: 17px;
        font-family: 'notosansregular';
        color: #2c2c2c;
		text-align: center;

        &:hover{
            color: #2c2c2c;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.encerrar__item{
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 100%;
        max-width: 100%;
        height: 50px;
		background-color: #af1a16;
        color: #fff;
        font-family: 'notosansbold';
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: all 0.3s linear;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-up (lg) {
			border-radius: 0px 0px 20px 20px;
		}

		&:after{
			display: block;
			content: '';
			background: #af1a16;
			background: linear-gradient(180deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1) 100%);
			height: 100%;
			width: 100%;
			top: 0px;
			position: absolute;
			left: 0px;
			z-index: 2;
			opacity: 0;
			transition: all 0.3s linear;
		}

		span{
			position: relative;
			z-index: 5;
		}

        &:hover{
            color: #fff;
            text-decoration: none;

			&:after{
				opacity: 1;
			}
        }
    }
}
.logado__nome{
    padding: 24px 15px 15px 15px;
    background-color: #fff;
    color: #2c2c2c;
	text-align: center;
    font-family: 'notosansregular';
    font-size: 16px;
    line-height: 18px;
	position: relative;

	span{
		font-family: 'notosansbold';
	}

	&:after{
		display: block;
		width: 100%;
		content: '';
		background: #aaaaaa;
		background: linear-gradient(90deg, rgba(#aaaaaa,0) 0%, rgba(#aaaaaa,1) 50%, rgba(#aaaaaa,0) 100%);
		height: 2px;
		position: absolute;
		bottom: 0px;
		left: 0px;
	}
}
.pesquisa__form{
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 100%;

	@include media-breakpoint-down (md) {
		background-color: #f8f7f7;
	}

	@include media-breakpoint-up (lg) {
		width: 440px;
	}

	.segura__form{
		display: flex;
		align-items: center;
		position: relative;
		max-width: 100%;
		width: 100%;
		background-color: #f8f7f7;
		overflow: hidden;
		border-radius: 10px;

		@include media-breakpoint-up (lg) {

			width: 440px;
		}

		label{
			display: block;
			width: 100%;
			margin-bottom: 0px;

			input{
				padding: 16px 14px;
				display: block;
				width: 100%;
				border: none;
				color: #2c2c2c;
				font-size: 15px;
				line-height: 17px;
				background-color: #f8f7f7;

				&:focus{
					outline: none;
				}

				&::placeholder{
					color: #2c2c2c;
				}
			}

		}
		button{
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 18px;
			width: 60px;
			border: none;
			background-color: #f8f7f7;
		}
	}
}
.dropmenu__categorias{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;

	a{
		padding: 10px;
		text-align: center;
	}
}

.segura__pesquisa{
	max-width: 440px;
	width: 100%;
}
.segura__desejo__topo{
	max-width: 65px;

	.carrinho__icone__topo{
		margin-left: 0px;
	}
}
.mobile__topo__carousel{
	background-color: #2c2c2c;
	color: #fff;
	padding: 10px 0px;
}
.lista__todas__categorias{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
	background-color: #af1a16;

	@include media-breakpoint-up (lg) {
		padding: 20px 15px;
		width: 100%;
	}
	@include media-breakpoint-down (md) {
		padding: 20px 0px;
		width: 100%;
		transform: unset !important;
		opacity: 1 !important;
	}

	.menu__item__titulo{
		font-family: 'notosansbold';
		text-align: center;
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 10px;
		color: #fff;
	}

	a{
		display: flex;
		text-align: center;
		width: 100%;
		justify-content: center;
		padding: 10px 20px;
		border-bottom: 1px solid #fff;
	}
	li{
		&:last-child{
			a{
				border-bottom: none;
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.segura__right{
		max-width: 220px;
		width: 100%;
	}
	.logado__toggler{
		button{
			 position: relative;
			 padding: 0px;
			 border: none;
			 background-color: transparent;
			 display: flex;
			 align-items: center;
			 color: #2c2c2c;
			 z-index: 2000;
		}
	 }
	.categoria__expanded{
		position: absolute;
		left: 0px;
		top: 100%;
		background-color: rgba(#af1a16, 1);
		max-width: 650px;
		width: 100%;
		z-index: 600;

		.row,
		.col-lg-8,
		.col-lg-4{
			padding: 0px !important;
			margin: 0px !important;
		}

		.categorias__principais{
			.dropdown__controls{
				position: relative;

				button{
					position: absolute;
					right: 10px;
					width: 20px;
					height: 20px;
					top: calc(50% - 10px);
					font-size: 12px;
					line-height: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					border-radius: 50%;
					background-color: #fff;
					color: #2c2c2c;
					padding: 0px;
				}
			}

			a{
				display: block;
				padding: 12px 10px;
				text-align: center;
				font-family: 'notosansregular';
				font-size: 16px;
				line-height: 18px;

				&:hover{
					background-color: #af1a16;
					text-decoration: none;
				}
			}
			li{
				&:hover{
					.categoria__link{
						a{
							background-color: #2c2c2c;
							text-decoration: none;
						}
					}
				}
			}
		}

		.grid__categorias{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			list-style: none;
			padding-left: 0px;
			margin-bottom: 0px;
			position: relative;
			padding-top: 20px;
			padding-bottom: 20px;

			&:after{
				height: 80%;
				width: 2px;
				right: -1px;
				content: '';
				position: absolute;
				top: 50%;
				background-color: rgba(#fff, 0.7);
				transform: translateY(-50%);
			}

			.dropdown__controls{
				position: relative;

				button{
					position: absolute;
					right: 10px;
					width: 20px;
					height: 20px;
					top: calc(50% - 10px);
					font-size: 12px;
					line-height: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					border-radius: 50%;
					background-color: #fff;
					color: #2c2c2c;
					padding: 0px;
				}
			}

			a{
				display: block;
				padding: 12px 16px;
				text-align: center;
				font-family: 'notosansregular';
				font-size: 14px;
				line-height: 16px;

				&:hover{
					background-color: #2c2c2c;
					text-decoration: none;
				}
			}
			li{
				border-right: 1px solid #fff;

				li{
					border-right: unset !important;
				}
				&:nth-child(3n) {
					border-right: none;
				  }
				&:hover{
					.categoria__link{
						a{
							background-color: #2c2c2c;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
	.segura__categorias{
		.container{
			position: relative;
		}
	}
	.main__cima{
		background-color: #2c2c2c;
		padding: 10px 0px;
		color: #fff;
	}
	.main__menu{
		display: flex;
		align-items: center;
		justify-content: space-between;

		li{
			margin-left: 30px;

			a{
				font-family: 'notosansbold';
				color: #555555;
				font-size: 14px;
				line-height: 18px;
				padding: 24px 0px;
				display: block;

				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	.categorias__menu{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.menu__item{
			&:hover{
				.menu__link{
					&:after{
						opacity: 1;
					}
				}
			}
		}

		.menu__link{
			display: flex;
			align-items: center;
			font-family: 'notosansbold';
			font-size: 16px;
			line-height: 16px;
			height: 50px;
			position: relative;
			z-index: 500;

			&:hover{
				text-decoration: none;

			}

			img{
				margin-right: 10px;
			}
		}

		.dropdown__controls{
			button{
				display: none;
			}
		}
	}
	.main__meio,
	.segura__categorias{
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.segura__categorias{
		background-color: #2c2c2c;
		color: #fff;
	}
	.topo__mobile{
		display: none;
	}
	.segura__right{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.main__meio{
		padding: 20px 0px;

		.container{
			align-items: center;
		}
	}
	.menu__item{
		position: relative;

		a{
			&:hover{
				text-decoration: none;
			}
		}

		&:hover{
			.dropmenu{
				display: block;
				animation: fadeInUpShort 0.3s linear;
			}
		}
	}
	.dropmenu{
		position: absolute;
		top: 100% !important;
		left: calc(50% - 128px);
		width: 256px;
		max-height: 300px;
		overflow-y: auto;
		background-color: #af1a16;
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		box-shadow: 0px 0px 8px rgba(#2c2c2c, 0.8);
		margin: 0px !important;
		z-index: 400;
		padding-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 10px;

		li{
			display: flex;

			&:last-child{
				a{
					border-bottom: none;
				}
			}

			a{
				display: flex;
				background-color: #af1a16;
				align-items: center;
				padding: 10px;
				text-align: center;
				justify-content: center;
				width: 100%;
				text-align: left;
				color: #fff;
				transition: all 0.3s linear;
				font-family: 'notosansregular';
				border-bottom: 1px solid rgba(#fff, 1);

				&:hover{
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
	.categorias__menu{
		.categoria__link{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 70px;
			padding-right: 10px;
			padding-left: 10px;
			font-family: 'notosansregular';
			position: relative;
			z-index: 100;
			font-size: 13px;
			line-height: 15px;

			&:after{
				position: absolute;
				content: '';
				bottom: 0px;
				left: 0px;
				width: 100%;
				height: 4px;
				z-index: -1;
				background-color: #af1a16;
				opacity: 0;
				transition: all 0.5s linear;
			}
			&:hover{
				text-decoration: none;

				&:after{
					opacity: 1;
				}
			}
		}
	}
	.segura__destaques{
		flex-grow: 1;
		width: 100%;
		max-width: 900px;
	}
	.logado__toggler{
		.logado__menu{
			min-width: 256px;
			overflow: hidden;
			max-width: 100%;
			left: calc(50% - 128px) !important;
			transform: unset !important;
			border-radius: 30px;
			border: 1px solid #af1a16;
			padding: 0px 0px;
			box-shadow: 0px 0px 3px rgba(#2c2c2c, 0.6);
			top: calc(100% + 10px) !important;
			display: none !important;

			&.show{
				display: block !important;
				animation: fadeInUpShort 0.3s linear;
			}
		}
	}
	.segura__todas__categorias{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		overflow-y: auto;
		max-height: calc(100vh - 256px);

		& > *{
			width: 33%;
		}
	}
}
@include media-breakpoint-down (md) {
	.logado__menu{
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: unset !important;
		position: relative !important;
		display: block !important;
		margin-bottom: 30px;
		width: 100% !important;
		top: unset !important;
		left: unset !important;
		transform: unset !important;
		margin: 0px !important;
		width: 100% !important;
	}
	.logado__toggler{
		flex-direction: column !important;
		width: 100%;

		button{
			border: none;
			width: 100%;
			background-color: transparent !important;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #2c2c2c;
		}
	}
	.topo__mobile{
		box-shadow: 0px 0px 5px rgba(#af1a16, 0.5);
		.container{
			display:flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.topo__main{
		position: fixed;
		left: -250px;
		width: 250px;
		transition: all 0.5s linear;
		height: 100%;
		overflow-y: auto;
		z-index: 1100;
		background-color: #fff;
		top: 0px;
		display: flex;
		flex-direction: column;

		.container{
			padding: 0px;
		}

		.main__cima{
			order: 2;
			display: none;
		}
		.main__meio{
			order: 1;
		}
		.segura__categorias{
			order: 3;
			background-color: #2c2c2c;

			.row,
			.col-lg-4,
			.col-lg-8,
			.container{
				padding: 0px !important;
				margin: 0px !important;
			}
			ul{
				list-style: none;
				padding-left: 0px;
				margin-bottom: 0px;
			}
			.row{
				display: flex;
				flex-direction: column;

				.dropmenu__categorias{
					background-color: #2c2c2c;

					a{
						background-color: #2c2c2c;
					}
				}

				.col-lg-4{
					order: 1;
				}
				.col-lg-8{
					order: 2;
				}
			}

			.categorias__menu{
				display: none;
			}
		}

		.container{
			padding: 0px;
		}

		.segura__pesquisa{
			display:none;
		}

		&.shown{
			left: 0px;
		}
	}
	.bg__menu{
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.5);
		animation: fadeIn 0.5s linear;
		z-index: 1050;

		&.hide{
			animation: fadeOut 0.5s linear;
		}
	}
	.segura__right{
		display:flex;
		flex-direction: column;
		align-items: center;

		.segura__carrinho__topo,
		.segura__desejo__topo{
			margin-bottom: 20px;
		}
	}
	.segura__login__topo{
		text-align: center !important;
		width: 100%;
		.login__toggler{
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			.carrinho__icone__topo{
				margin-right: 0px;
				margin-bottom: 5px;
			}
		}
	}
	.main__logo{
		padding: 15px;
		margin-bottom: 5px;
	}
	.menu__item{
		.dropmenu{
			background-color: #af1a16;
			color: #fff;
		}

		.dropdown__controls{
			display: flex;
			align-items: center;
			position: relative;

			button{
				position: absolute;
				top: calc(50% - 10px);
				right: 10px;
				border: none;
				border-radius: 50%;
				background-color: #2c2c2c;
				color: #fff;
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;

				&[aria-expanded="true"]{
					transform: rotate(90deg);
				}
			}
		}

		a{
			padding: 15px 10px;
			display: flex;
			align-items: center;
			width: 100%;
			background-color: #af1a16;
			font-family: 'notosanslight';
			color: #fff;
			font-size: 14px;
			line-height: 16px;
			border-bottom: 1px solid #fff;

			img{
				margin-right: 10px;
			}
		}
	}
	.mbl__toggler{
		border: none;
		padding: 0px;
		font-size: 26px;
		line-height: 26px;
		background-color: transparent;
	}
	.topo__mobile{
		.container{
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.mbl__logo{
		max-width: 150px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.sociais__topo{
		color: #fff;
		background-color: #9f9f9f;
		padding: 5px 10px 20px 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		span{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.segura__connoto__topo{
		text-align: center;
		padding: 20px 10px;
		flex-direction: column;
		color: #fff;
		background-color: #9f9f9f;

		.telefone__connoto__topo,
		.email__connoto__topo{
			margin-bottom: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;

			& > * {
				margin-right: 0px !important;
				margin-bottom: 5px !important;
			}
		}

		.barrinha{
			display: none;
		}
	}
}

.btn__toggle__categ{
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;
	border: none;
	color: #fff;
	height: 70px;
	font-family: 'notosanslight';
	position: relative;
	z-index: 40;
	padding-right: 20px;
	font-size: 14px;
	line-height: 14px;

	&:after{
		width: 100vw;
		height: 70px;
		background-color: #af1a16;
		right: 0px;
		content:'';
		position: absolute;
		top: 0px;
		z-index: -1;
	}

	&:before{
		width: 24px;
		height: 18px;
		display: block;
		content: '';
		margin-right: 10px;
		background-image: url('../images/categoria__icone.png');
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;

		@include media-breakpoint-only (lg) {
			display: none;
		}
	}

	@include media-breakpoint-down (md) {
		width: 100%;
		background-color: #2c2c2c;
		height: 50px;

		&:after {
			height: 50px;
			background-color: #2c2c2c;
		}
	}
}
@-webkit-keyframes fadeInUpShort {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, 15%, 0);
	  transform: translate3d(0, 15%, 0);
	}

	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }

  @keyframes fadeInUpShort {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, 15%, 0);
	  transform: translate3d(0, 15%, 0);
	}

	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
@include media-breakpoint-up (lg) {
	.topo{

		&.fixed-topo{
			position: sticky;
			top: 0px;
			z-index: 900;
			width: 100%;
			background-color: #fff;

			.main__logo{
				transition: all 0.3s linear;
				max-width: 257px;
			}

			.categorias__menu{
				.categoria__link{
					transition: all 0.3s linear;
				}

			}
			.btn__toggle__categ{
				transition: all 0.3s linear;

				&:after{
					transition: all 0.3s linear;
				}
			}

			.mobile__topo__carousel{
				transition: all 0.3s linear;

				.texto__topo{
					transition: all 0.3s linear;
				}
			}

			&.fx{
				.mobile__topo__carousel{

					overflow: hidden;
					padding: 0px;

					.texto__topo{
						line-height: 0px !important;
					}
				}
			}
			&.fx-2{
				.main__meio{
					.main__logo{
						max-width: 180px;
					}
				}
				.categorias__menu{
					.categoria__link{
						height: 40px !important;
					}

				}
				.btn__toggle__categ{
					height: 40px !important;

					&:after{
						height: 40px;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.topo{

		&.fixed-topo{
			position: sticky;
			top: 0px;
			z-index: 900;
			width: 100%;
			background-color: #fff;

			.mobile__topo__carousel{
				transition: all 0.3s linear;

				.texto__topo{
					transition: all 0.3s linear;
				}
			}

			&.fx{
				.mobile__topo__carousel{

					overflow: hidden;
					padding: 0px;

					.texto__topo{
						line-height: 0px !important;
					}
				}
			}
		}
	}
}
.home__link{
	display: block;
	color: #af1a16;
	text-align: center;
	padding: 10px;
	font-family: 'notosansbold';
}
