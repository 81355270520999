.row__form{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.login__form,
.login--cadastro__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.titulo__interna__login{
	font-family: 'notosansbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
}
.login__desc{
    margin-bottom: 19px;
    padding-top: 5px;
    font-family: 'notosansregular';
    color: #2c2c2c;
    padding-left: 0px;
	font-size: 16px;
	line-height: 18px;
	min-height: 36px;
}
.esqueci__link{
    font-family: 'notosansregular';
    font-size: 14px;
    line-height: 16px;
    color: #2c2c2c;
    margin-top: 7px;
	margin-left: 10px;
    display: inline-flex;
    position: relative;
    align-items: center;
    transition: all 0.3s linear;
	font-style: italic;
}
.login__btn{
    padding-top: 5px;
	justify-content: center;
	display: flex;
}
.login--cadastro__btn{
    padding-top: 23px;
	justify-content: center;
	display: flex;
}
.titulo__login{
    font-family: 'notosansbold';
    color: #2c2c2c;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}
@include media-breakpoint-down (md) {
    .login__btn{
        margin-bottom: 40px;
    }
}
