.texto__topo{
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	display: block;
	width: 100%;
	span{
		font-family: 'notosansbold';

	}
}
.opcao__titulo{
	text-align: left;
	font-family: 'notosansbold';
	margin-bottom: 15px;
	padding-top: 10px;
}

.cores__titulo{
	text-align: left;
	font-family: 'notosansbold';
	margin-bottom: 5px;
	padding-top: 10px;
}
.opcoes__produto{
	display: flex;
	flex-wrap: wrap;

	& > *{
		margin-bottom: 10px;
		margin-right: 10px;
	}
}
.carousel__topo{
	position: relative;
	padding: 0px 10px;



	.carousel-controls__custom{
		.carousel-control-prev__custom,
		.carousel-control-next__custom{
			position: absolute;
			color: #fff;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			z-index: 500;

			&:hover{
				color: #af1a16;
			}
		}
		.carousel-control-prev__custom{
			left: -15px;

			@include media-breakpoint-down (md) {
				left: 5px;
			}
		}
		.carousel-control-next__custom{
			right: -15px;

			@include media-breakpoint-down (md) {
				right: 5px;
			}
		}
	}
	.carousel-inner{
		z-index: 300;
	}
}
.banner__carousel{
	position: relative;
	max-width: 1920px;
	overflow: hidden;
	width: 100%;

	.carousel-indicators{
		text-indent: 0px;
		bottom: 30px;
		justify-content: center;

		@include media-breakpoint-down (sm) {
			bottom: 5px;
		}

		li{
			width: 32px;
			height: 30px;
			background-color: transparent;
			opacity: 1;
			border: none;
			margin: 0px 4px 0px 4px;
			position: relative;
			border-radius: 16px;
			text-indent: 0px;
			transition: all 0.3s linear;
			position: relative;
			color: #fff;
			text-align: center;
			font-size: 20px;
			line-height: 20px;
			font-family: notosansbold;

			&:after{
				width: 30px;
				height: 3px;
				border-radius: 3px;
				content: '';
				position: absolute;
				bottom: 0px;
				left: 0px;
				background-color: #fff;
				transition: all 0.3s linear;
				opacity: 0;
			}

			&.active{
				&:after{
					opacity: 1;
					color: #fff
				}
			}
		}
	}

	a{
		display: block;
	}

	.carousel-inner{
		position: relative;
		max-width: 1920px;
		width: 160%;
		left: 50%;
		transform: translateX(-50%);
	}

	.carousel-controls__custom{

		.carousel-control-prev__custom,
		.carousel-control-next__custom{
			position: absolute;
			color: #fff;
			opacity: 0.3;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			font-size: 50px;
			line-height: 50px;
			z-index: 700;

			@include media-breakpoint-down (md) {
				opacity: 0.7;
			}

			&:hover{
				opacity: 0.7;
			}
		}
		.carousel-control-prev__custom{
			left: 10%;

			@include media-breakpoint-up (lg) {
				left: -10%;
			}
		}
		.carousel-control-next__custom{
			right: 10%;

			@include media-breakpoint-up (lg) {
				right: -10%;
			}
		}
	}
	&:hover{
		.carousel-controls__custom{
			.carousel-control-prev__custom{
				left: 10%
			}
			.carousel-control-next__custom{
				right: 10%;
			}
		}
	}
}
.produto__card{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 352px;
	margin-bottom: 10px;
	border: 1px solid rgba(#2c2c2c, 0.2);
	border-radius: 0px 0px 0px 0px;
	text-align: center;
	padding: 5px;

	&:hover{
		.segura__imagem__grande{
			.segura__btns__card{
				opacity: 1;
			}
			.imagem__original{
				img{
					opacity: 0;
				}
			}
		}
		a{
			text-decoration: none;

			&:hover{
				text-decoration: none;
			}
		}
	}

	.segura__imagem__grande{
		position: relative;
		margin-bottom: 10px;

		.imagem__original{
			img{
				position: relative;
				z-index: 30;
				transition: all 0.3s linear;
			}
		}
		.imagem__alt{
			img{
				position: absolute;
				z-index: 20;
				transition: all 0.3s linear;
				top: 0px;
				left: 0px;
			}
		}

		a{
			.produto__card__tags{
				z-index: 40;
			}
		}
		.segura__btns__card{
			position: absolute;
			top: 0px;
			left: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 30;
			width: 100%;
			height: 100%;
			transition: all 0.3s linear;

			background-color: rgba(#fff, 0.3);

			@include media-breakpoint-up (lg) {
				opacity: 0;
			}
			@include media-breakpoint-down (md) {
				background-color: transparent;
			}
		}

	}

	.produto__card__imagem{
		position: relative;
		display: block;

		.produto__card__tags{
			position: absolute;
			left: 10px;
			bottom: 10px;
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			.produto__card__tag{
				width: 60px;
				min-width: 60px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				padding: 2px 5px;
				box-shadow: 0px 0px 5px rgba(#000, 0.4);
				margin-right: 10px;
				font-family: 'notosansbold';
				font-size: 13px;
				line-height: 13px;
				color: #fff;
				background: #af1a16;
				background: linear-gradient(180deg, rgba(#af1a16,1) 0%, rgba(#8c2c26,1) 100%);
				margin: 2px;
			}
		}
	}

	a{
		&:hover{
			text-decoration: none;
		}
	}
	.produto__comprar__btn__card,
	.produto__desejo__btn__card{

			width: 45px;
			height: 45px;
			min-width: 45px;
			background-color: transparent;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #2c2c2c;
			border: none;
			margin: 5px;
			border-radius: 50%;
			transition: all 0.3s linear;

			&:hover{
				color: #2c2c2c;
			}
		}

}
.produto__card__titulo{
	font-family: 'notosansbold';
	font-size: 20px;
	line-height: 24px;
	min-height: 60px;
	margin-bottom: 15px;
	color: #2c2c2c;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;

	&:after{
		display: block;
		width: 100%;
		content: '';
		background: #aaaaaa;
		background: linear-gradient(90deg, rgba(#aaaaaa,0) 0%, rgba(#aaaaaa,1) 50%, rgba(#aaaaaa,0) 100%);
		height: 2px;
		position: absolute;
		bottom: 0px;
		left: 0px;
	}
}
.produto__card__valor{
	text-align: center;
	color: #2c2c2c;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	position: relative;
	min-height: 50px;
	padding: 0px 16px 0px 86px;

	.somente__valores{
		display: block;
		margin: 0px 6px;

		@include media-breakpoint-down (md) {
			text-align: center;
		}
	}


	@include media-breakpoint-down (md) {
		flex-direction: column;
		padding: 0px 16px 0px 16px;
		align-items: stretch;

		.segura__btn__produto{
			order: 3;
		}
		.somente__valores{
			order: 1;
		}
		.parcela__valores{
			order: 2;
		}
	}

	.produto__valor__promocao{
		font-size: 14px;
		line-height: 17px;
		font-family: 'notosanslight';
		text-align: left;

		@include media-breakpoint-down (md) {
			text-align: center;
		}

		span{
			text-decoration: line-through;
		}
	}
}
.categoria__card{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 220px;
	width: 100%;
	text-align: center;
	min-height: 200px;
	position: relative;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		&:after{
			background-color: #af1a16;
		}
	}

	&:after{
		width: 150px;
		height: 150px;
		position: absolute;
		content: '';
		left: calc(50% - 75px);
		top: calc(50% - 75px);
		background-color: #dfdfdf;
		border-radius: 20px;
		overflow: hidden;
		transform: rotate(45deg);
		z-index: -1;
		transition: all 0.3s linear;
	}
}
.ultima{
	padding-bottom: 56px !important;
}
.carrinho__btn__baixo{
	display: flex;
	align-items: center;
	width: 100%;
	border: none;
	color: #fff;
	background-color: #2c2c2c;
	border-radius: 10px;
	font-family: 'notosansbold';
	overflow: hidden;
	max-height: 50px;
	transition: all 0.3s linear;
	height: 50px;
	position: relative;

	@include media-breakpoint-up (lg) {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 16px;
	}

	span{
		text-align: center;
		display: block;
		transition: all 0.3s linear;
	}

	&:hover{
		&:after{
			opacity: 1;
		}

		@include media-breakpoint-up (lg) {
			width: calc(100% - calc(0px + 30px));
		height: 100%;
		}


		span{
			display: block;
			text-align: center;
			opacity: 1;
		}
	}

	@include media-breakpoint-up (lg) {
		padding: 0px;
		width: 65px;
		min-width: 65px;

		span{
			display: none;
			text-align: center;
			opacity: 0;
		}
	}

	&:focus{
		outline: none;
	}

	&:after{
		background: #af1a16;
		background: linear-gradient(180deg, rgba(#af1a16,1) 0%, rgba(#8c2c26,1) 100%);
		opacity: 0;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	.carrinho__btn__baixo__icone{
		color: #fff;
		width: 65px;
		min-width: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		z-index: 30;
		padding: 10px 10px;
		left: 0px;
		top: 0px;

		@include media-breakpoint-up (lg) {
			position: absolute;
		}
	}

	.carrinho__btn__baixo__texto{
		width: 100%;
		flex-grow: 1;
		text-align: center;
		position: relative;
		z-index: 30;
	}
}
.parcela__valores{
	display: block;
	text-align: right;

	@include media-breakpoint-down (md) {
		text-align: center;
		margin-bottom: 15px;
	}
}
.produto__valor__total{
	color: #2c2c2c;
	text-align: center;
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
}
.segura__bannerzinhos{
	padding: 70px 0px 40px 0px;
	background-color: #dfdfdf;

	.bannerzinho__link{
		display: block;
		max-width: 540px;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		border-radius: 20px;
		box-shadow: 0px 0px 10px rgba(#000,0.4);

		img{
			transition: all 0.3s linear;
		}

		&:hover{
			img{
				transform: scale(1.1);
			}
		}
	}
}
.titulo__secao{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	color: #2c2c2c;
	margin-bottom: 50px;
	text-align: center;
}
.owl__produtos.owl-carousel{
	position: relative;

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			color: #2c2c2c;
			border: none;
			background-color: transparent;
			opacity: 0.3;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			font-size: 30px;
			line-height: 30px;
			z-index: 700;

			@include media-breakpoint-down (md) {
				opacity: 0.7;
			}

			&:hover{
				opacity: 0.7;
			}
		}
		button.owl-prev{
			left: -15px;

			@include media-breakpoint-up (lg) {
				left: -500px;
				opacity: 0;
			}
		}
		button.owl-next{
			right: -15px;

			@include media-breakpoint-up (lg) {
				right: -500px;
				opacity: 0;
			}
		}
	}

}
.mais__vistos{
	padding: 70px 0px 0px 0px;
	overflow: hidden;
	width: 100%;
	max-width: 1920px;

	@include media-breakpoint-up (lg) {
		&:hover{
			.owl__produtos{

				.owl-nav{
					button.owl-prev{
						left: -30px;
						opacity: 1;
					}
					button.owl-next{
						right: -30px;
						opacity: 1;
					}
				}

			}
		}
	}
}
.blog{
	overflow: hidden;
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.container{
		max-width: unset !important;
		padding: unset !important;
	}

	.row__custom{
		display: flex;
		position: relative;

		@include media-breakpoint-down (md) {
			flex-direction: column;
		}

		.col__blog__texto{
			width: 100%;
			max-width: 40%;
			background-color: #dfdfdf;
			color: #2c2c2c;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 90px 15px;

			@include media-breakpoint-down (md) {
				max-width: unset;
			}

			.texto__conteudo{
				max-width: 400px;
				width: 100%;

				@include media-breakpoint-down (md) {
					max-width: unset;
				}
			}
		}
		.col__blog__imagem{
			width: 100%;
			max-width: 60%;
			background-color: #2c2c2c;
			color: #dfdfdf;
			padding: 90px 15px;

			@include media-breakpoint-down (md) {
				max-width: unset;
			}
		}
	}
}
.titulo__blog{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	color: #2c2c2c;
	margin-bottom: 10px;
}
.sub__blog{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	color: #2c2c2c;
	margin-bottom: 10px;
}
.desc__blog{
	font-size: 15px;
	line-height: 18px;
	color: #2c2c2c;
	margin-bottom: 30px;
}
.blog__botao{
	display: inline-block;
	max-width: 100%;
	background-color: #2c2c2c;
	padding: 15px 24px;
	border-radius: 10px;
	color: #dfdfdf;
	font-family: 'notosansbold';
	transition: all 0.3s linear;

	&:hover{
		background-color: #af1a16;
		color: #fff;
		text-decoration: none;
	}
}
.blog__card{
	display: block;
	width: 100%;
	max-width: 255px;
	margin-left: auto;
	margin-right: auto;

	.blog__card__imagem{
		margin-bottom: 10px;
	}
	.blog__card__titulo{
		font-family: 'notosansbold';
		font-size: 20px;
		line-height: 24px;
		color: #dfdfdf;
		margin-bottom: 5px;
		--linhas: 3;
		min-height: calc(24px * 3);
	}
	.blog__card__desc{
		--linhas: 4;
		font-size: 15px;
		line-height: 18px;
		min-height: calc(18px * 4);
	}

	&:hover{
		text-decoration: none;

		.blog__card__titulo{
			color: #af1a16 !important;
		}
	}
}
.blog__interna__header{
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 3px solid #af1a16;
}
.titulo__blog__interna{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	margin-bottom: 10px;
}
.blog__interna__header__baixo{
	display: flex;
	flex-wrap: wrap;

	.autor,
	.data{
		display: flex;
		align-items: center;

		.fas,
		.far{
			margin-right: 5px;
		}
	}

	.autor{
		margin-right: 30px;
	}
}
.blog__imagem{
	text-align: center;
	margin-bottom: 30px;
}
.blog__owl.owl-carousel{
	.owl-nav{
		padding-top: 20px;
		button.owl-prev,
		button.owl-next{
			color: #dfdfdf;
			font-size: 30px;
			line-height: 30px;
			margin: 5px;
			border: none;
			background-color: transparent;
			transition: all 0.3s linear;

			&:hover{
				color: #af1a16;
			}
		}
	}
}
.destaques__card{
	display: flex;
	width: 100%;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-height: 380px;

	&:hover{
		text-decoration: none;

		.destaques__titulo{
			background-color: #af1a16;
		}
	}

	.destaques__imagem{
		width: 255px;
		max-width: 100%;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 0px 0px 10px rgba(#000, 0.6);
	}
	.destaques__titulo{
		background-color: #2c2c2c;
		writing-mode: vertical-rl;
		text-orientation: mixed;
		color: #fff;
		padding: 15px 30px;
		text-align: center;
		height: 70%;
		margin-top: auto;
		margin-bottom: auto;
		font-family: 'notosansbold';
		font-size: 16px;
		line-height: 20px;
		border-radius: 10px 0px 0px 10px;
		transition: all 0.3s linear;

	}
}
.modal__full{
	position: relative;
	.modal-content{
		position: fixed;
		width: 100%;
		left: 0px;
		top: 0px;
		transform: unset !important;
		height: 1080px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		overflow-y: auto;
	}
}
.line__clamp__alt {
	max-width: calc(var(--linhas, 2) * 1.5em);
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--linhas, 2);
}
.destaques{
	padding: 70px 0px;

	@include media-breakpoint-up (lg) {
		&:hover{
			.owl__produtos{

				.owl-nav{
					button.owl-prev{
						left: -30px;
						opacity: 1;
					}
					button.owl-next{
						right: -30px;
						opacity: 1;
					}
				}

			}
		}
	}
}
.funcionamento{
	padding-top: 170px;
	padding-bottom: 150px;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.funcionamento__card{
	max-width: 600px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #fff;
	padding: 20px 15px;
	background-color: rgba(#000, 0.7);
	color: #fff;

	.funcionamento__texto{
		display: flex;
		justify-content: center;
		flex-direction: column;
		min-height: 176px;

		div{
			margin-bottom: 6px;
		}
	}
	.col-md-6{
		&:first-child{
			border-right: 1px solid #fff;
			padding-right: 20px;
		}
		&:last-child{
			padding-left: 20px;
		}
	}
}
.funcionamento__texto{
	font-family: 'notosanslight';
	font-size: 20px;
	line-height: 24px;
}
