.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 20px;

	.paginacao__item{
		a{
            font-family: 'notosansbold';
            font-size: 15px;
            line-height: 15px;
            color: #2c2c2c;
            display: flex;
            width: 34px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 34px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
			border: none;
            margin: 0px 2px;
            padding: 0px;
            padding-left: 1px;
            padding-top: 1px;
			background-color: transparent;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
			background: #af1a16;
			background: linear-gradient(0deg, rgba(#8e2b26,1) 0%, rgba(#af1a16,1)  100%);
                color: #fff;
                text-decoration: underline;
           }
        }

		&:hover{
			a{
                text-decoration: underline;
            }
		}
	}
}
.categoria__interna__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.filtros__categorias{
	display: flex;
	justify-content: center;
	margin-bottom: 50px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
	}

	.segura__filtro{
		width: 50%;
		position: relative;

		@include media-breakpoint-up (lg) {
			&:after{
				width: 2px;
				height: 30px;
				background-color: #c7c7c7;
				position: absolute;
				content: '';
				top: calc(50% - 15px);
				right: -1px;
			}
		}

		.v-select-output{
			display: flex;
			justify-content: center;
			border-radius: 0px;
			border: none;
			border-top: 1px solid #c7c7c7;
			border-bottom: 1px solid #c7c7c7;
			font-family: 'notosansbold';
			color: #2c2c2c;
			padding-top: 20px;
			padding-bottom: 20px;
			height: auto;

			&:before{
				float: none;
				order: 2;
				border-left-width: 0.4em;
				border-right-width: 0.4em;
				border-top-width: 0.4em;
			}
		}
	}
}

.box__filtro__ordenacao{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #c7c7c7;
	border-bottom: 1px solid #c7c7c7;
	margin-bottom: 50px;

	@include media-breakpoint-down(sm){
		flex-direction: column;
		align-items: flex-start;
	}

	.filtro{
		margin-right: 200px;
		position: relative;

		@include media-breakpoint-down(sm){
			margin-right: 0;
		}

		&::before{
			content: '';
			position: absolute;
			width: 2px;
			height: 30px;
			border-radius: 0;
			background: #dddddd;
			right: -90px;
			top: calc(50% - 15px);

			@include media-breakpoint-down(sm){
				display: none;
			}
		}

		&:last-child{
			margin-right: 0;

			&::before{
				display: none;
			}
		}
	}

}

.box__mid{
	.categoria__box{
		margin-bottom: 20px;
	}
}


.categoria__header{
	background: #2c2c2c;
	color: #fff;
	font-family: 'notosansbold';
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	padding: 18px 10px 20px;
	border-radius: 5px 5px 0px 0px;
}
.categorias__listagem{
	border-left: 1px solid rgba(#2c2c2c, 0.5);
	border-right: 1px solid rgba(#2c2c2c, 0.5);
	border-bottom: 1px solid rgba(#2c2c2c, 0.5);
	border-radius: 10px;
	overflow: hidden;
	background-color: #fff;

	.categoria__box{
		padding: 15px !important;
	}
}
.titulo__categoria{
	font-family: 'notosansbold';
	font-size: 15px;
	line-height: 18px;
	color: #2c2c2c;
	margin-bottom: 10px;
}


.dropdown-menu-filtro{

	.categoria__box{
		margin-right: 113px;

		@include media-breakpoint-down(lg){
			margin-right: 20px;
		}
	}
}

.categoria__lista{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;

	li{
		margin-bottom: 15px;
	}

	@include media-breakpoint-down(md){
		margin-bottom: 10px;
	}
}
.categoria__item__interna{
	display: flex;
	font-family: 'notosansregular';
	font-size: 15px;
	line-height: 18px;
	color: #2c2c2c;
	transition: color .4s linear;

	&:hover{
		text-decoration: underline;
		color: map-get($cores, cor-secundaria);
	}
}
.categoria__quant{
	margin-left: 5px;
}
.categoria__mais{
	border: none;
	background-color: transparent;
	padding: 0px;
	font-family: 'notosansbold';
	font-size: 14px;
	line-height: 15px;
	color: #2c2c2c;
	margin-top: 5px;
	display: inline-block;

	@include media-breakpoint-down(md){
		margin-bottom: 30px;
	}

	span{
		font-size: 12px;
		line-height: 14px;
	}

	&:focus{
		outline: none;
	}
}
.categoria__segura__radio{
	margin-bottom: -5px;
	font-family: 'notosansbold';
	padding-left: 10px;
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #2c2c2c;
					background-color: #fff;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: 'notosansregular';
		font-size: 15px;
		line-height: 18px;
		color: #2c2c2c;
		transition: all 0.3s linear;
		cursor: pointer;
		font-style: italic;

		&:hover{

			&:before{
				border-color: #2c2c2c;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #000;
			transition: all 0.3s linear;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #2c2c2c;
			top: 50%;
			left: 3px;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.categoria__mais{
	&[aria-expanded="true"] {
		&.categ__mais{
			display: none;
		}
	}
	&[aria-expanded="false"] {
		&.categ__menos{
			display: none;
		}
	}
}
.subcategoria__lista{
	padding-left: 15px;
	list-style: none;
	margin-bottom: 5px;
	padding-top: 5px;

	li{
		margin-bottom: 5px;
	}

	.subcategoria__item{
		margin-bottom: 5px;

		.categoria__item__interna{
			font-size: 14px;
			line-height: 18px;
			font-family: 'notosansregular';
		}
	}
}
.segura__categoria__controls{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;

	button{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: transparent;
		color: #2c2c2c;
		border-radius: 50%;
		border: none;
		padding: 0px;
		font-size: 16px;
		line-height: 16px;
		margin-left: 5px;
		margin-bottom: 2px;
		transition: all 0.3s linear;
		padding-top: 1px;

		.fa-plus{
			display: block;
		}
		.fa-minus{
			display: none;
		}

		&:focus{
			outline: none;
		}
		&[aria-expanded="true"] {
			.fa-plus{
				display: none;
			}
			.fa-minus{
				display: block;
			}
		}
	}
}
.dropdown{


	.btn__filtro{
		width: 230px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
		box-shadow: none;
		font-size: 15px;
		font-family: 'notosansbold';
		text-transform: uppercase;
	}

	.btn__ordem{
		width: 104px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
		box-shadow: none;
		font-size: 15px;
		font-family: 'notosansbold';
		text-transform: uppercase;
	}

	// .dropdown-menu{
	// 	z-index: 1000;
	// 	border-radius: 0;
	// 	box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
	// 	-webkit-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
	// 	-moz-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
	// 	top: 9px !important;

	// 	.drop-content{
	// 		display: flex;
	// 		padding: 20px;

	// 		@include media-breakpoint-down(md){
	// 			flex-direction: column;
	// 			align-items: flex-start;
	// 		}
	// 	}


	// }
	.box__filtro__ordenacao{
		position: relative;
	}
	.dropdown-menu-filtro{


		@include media-breakpoint-up(lg){
			width: 1110px;
			left: -50px !important;
		}

		@include media-breakpoint-down(lg){
			width: 700px;
			left: 0 !important;
			transform: none;
		}

		@include media-breakpoint-down(md){
			position: relative;
			min-width: 350px !important;
			width: 350px !important;
			top: 0px !important;
			top: 100% !important;
			position: absolute;
			transform: none !important;

		}
		@include media-breakpoint-down(xs){
			position: relative;
			min-width: 300px !important;
			width: 300px !important;
			top: 100% !important;
			position: absolute;
			transform: none !important;

		}
	}

	.btn__filtrar{
		width: 160px;
		height: 40px;
		border-radius: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		background: map-get($cores, cor-primaria);
		font-size: 13px;
		font-family: 'notosansbold';
		text-transform: uppercase;
		transition: background .4s linear;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;

		&:hover{
			background: map-get($cores , cor-secundaria);
		}
	}
}
.row__categ{
	.col-sm-6{
		margin-bottom: 20px;
	}
	.col-lg-8{
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}
}
.produtos__interna{
	.titulo__interna{
		font-size: 25px;
		line-height: 29px;
	}
}
.contato__interna__desc{
	text-align: center;
	max-width: 900px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}
.contato__interna__sub{
	font-family: 'notosansbold';
	font-size: 30px;
	line-height: 34px;
	margin-bottom: 10px;
	color: #2c2c2c;
}
.internas{
	color: #2c2c2c;
}
